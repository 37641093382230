import { useLayoutEffect, useState } from "react"

export function useWindowSize() {
  const [height, setHeight] = useState()
  const [width, setWidth] = useState()

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight)
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  return { height, width }
}
