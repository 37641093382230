import { useWindowSize } from "../hooks/useWindowSize"

export const Viewport = ({ children, from, to }) => {
  const windowSize = useWindowSize()
  const windowWidth = windowSize.width

  if (from && to) {
    if (windowWidth >= from && windowWidth < to) {
      return children
    } else {
      return null
    }
  }

  if (from) {
    if (windowWidth >= from) {
      return children
    } else {
      return null
    }
  }

  if (windowWidth < to) {
    return children
  } else {
    return null
  }
}
