import React from "react"
import { css } from "@emotion/react"
import facebook from "../images/facebook.svg"
import instagram from "../images/instagram.svg"
import { mediaQuery } from "../utils/mediaQuery"

const containerStyle = css`
  display: grid;
  grid-template-areas:
    "media"
    "copyright";
  grid-auto-rows: 40px auto;
  text-align: center;
  background-color: black;
  padding: 50px 0;
  color: white;
`

const mediaLinksContainerStyle = css`
  display: flex;
  justify-content: space-around;
  grid-area: media;
  margin: 0 auto;
  width: 50%;

  ${mediaQuery[1]} {
    width: 200px;
  }
`

const imageStyle = css`
  height: 30px;
  width: 30px;
  opacity: 0.6;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`

const copyrightContainerStyle = css`
  grid-area: copyright;
  opacity: 0.5;
  margin: 0 auto;
  width: 75%;

  ${mediaQuery[1]} {
    width: 400px;
  }
`

const linkStyles = css`
  height: fit-content;
`

const Footer = () => {
  return (
    <footer css={containerStyle}>
      <div css={mediaLinksContainerStyle}>
        <a css={linkStyles} href="https://www.instagram.com/tujofilms/">
          <img css={imageStyle} src={instagram} alt="instagram" />
        </a>
        <a css={linkStyles} href="https://www.facebook.com/Tujofilms/">
          <img css={imageStyle} src={facebook} alt="facebook" />
        </a>
      </div>
      <div css={copyrightContainerStyle}>
        <p
          css={css`
            font-size: 1.2rem;
          `}
        >
          © Bard Brothers {new Date().getFullYear()}
        </p>
        <p
          css={css`
            font-size: 0.8rem;
          `}
        >
          The material on this site may not be reproduced, distributed,
          transmitted, cached or otherwise used, except with prior written
          permission of Bard Brothers.
        </p>
      </div>
    </footer>
  )
}

export default Footer
