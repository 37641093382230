import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { css } from "@emotion/react"
import blackLogo from "../images/tujo-logo-black.png"
import whiteLogo from "../images/tujo-logo-white.png"
import { mediaQuery } from "../utils/mediaQuery"
import { Viewport } from "./viewport"
import { breakpointConstants } from "../constants/breakpoints"
import hamBurgerIcon from "../images/hamburger.svg"
import closeButtonIcon from "../images/close-button.svg"

const containerStyles = (hasScrolled, isHome) => theme => css`
  background-color: white;
  color: black;
  ${mediaQuery[1]} {
    color: ${hasScrolled ? "black" : "white"};
    ${isHome
      ? `
background-color: ${hasScrolled ? "white" : "rgba(0,0,0,0.6)"};
`
      : `
      background-color: ${hasScrolled ? "white" : "black"};
`}

    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    transition: background-color 0.5s ease-in;
    transition: color 0.5s ease-out;
    display: flex;
    height: ${theme.heights.header};
  }
`
const logoContainerStyles = theme => css`
  height: ${theme.heights.header};
  text-align: center;
  padding-top: 2%;

  ${mediaQuery[1]} {
    width: 100%;
    text-align: start;
    padding: 0;
    height: 65px;
    margin-top: 3px;
  }
`

const logoLinkStyles = css`
  color: inherit;
  text-decoration: none;

  ${mediaQuery[1]} {
    margin: 10px 0px 0px 40px;
  }
`

const logoStyles = css`
  height: 100%;
`

const linkStyle = css`
  font-size: 1rem;
  margin: auto;
  padding: 20px;

  a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;

    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .active_link {
    text-decoration: underline;
  }
`

const mobileBurgerStyles = css`
  position: absolute;
  right: 0;
  top: 0;
  height: 30px;
  padding: 1.8em 10px;
`

const linksContainerStyles = menuVisible => theme => css`
  opacity: ${menuVisible ? "1" : "0"};
  visibility: ${menuVisible ? "visible" : "hidden"};
  transition: opacity 0.25s, visibility 0.25s;
  position: absolute;
  color: white;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  top: 0;
  padding-top: ${theme.heights.header};
  z-index: 4;

  ${mediaQuery[1]} {
    visibility: visible;
    opacity: 1;
    background: none;
    height: auto;
    transform: none;
    color: inherit;
    position: static;
    display: flex;
    justify-content: space-between;
    padding-top: 0;
  }
`

const mobileMenuHeaderStyles = css`
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px 20px;
`

const mobileCloseButtonStyles = css`
  position: absolute;
  right: 0;
  top: 0;
  height: 34px;
  padding: 1.5em 8px;
`

const Header = ({ siteTitle, isHome }) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false)

  useEffect(() => {
    function handleScroll() {
      if (document.documentElement.scrollTop > 68) {
        setHasScrolled(true)
      } else {
        setHasScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const toggleMenu = () => {
    return menuVisible ? setMenuVisible(false) : setMenuVisible(true)
  }

  return (
    <header css={containerStyles(hasScrolled, isHome)}>
      <div css={logoContainerStyles}>
        <Link to="/" css={logoLinkStyles}>
          <Viewport to={breakpointConstants.md}>
            <img css={logoStyles} src={blackLogo} alt={siteTitle} />
          </Viewport>
          <Viewport from={breakpointConstants.md}>
            <img
              css={logoStyles}
              src={hasScrolled ? blackLogo : whiteLogo}
              alt={siteTitle}
            />
          </Viewport>
        </Link>
      </div>
      <Viewport to={breakpointConstants.md}>
        <input
          type="image"
          alt="Open navigation menu"
          src={hamBurgerIcon}
          css={mobileBurgerStyles}
          onClick={e => {
            e.preventDefault()
            toggleMenu()
          }}
        />
      </Viewport>
      <div css={linksContainerStyles(menuVisible)}>
        <Viewport to={breakpointConstants.md}>
          <h3 css={mobileMenuHeaderStyles}>MENU</h3>
          <input
            type="image"
            alt="Close navigation menu"
            src={closeButtonIcon}
            css={mobileCloseButtonStyles}
            onClick={e => {
              e.preventDefault()
              toggleMenu()
            }}
          />
        </Viewport>
        <h1 css={linkStyle}>
          <Link activeClassName="active_link" to="/">
            HOME
          </Link>
        </h1>
        <h1 css={linkStyle}>
          <Link
            activeClassName="active_link"
            to="/services"
            partiallyActive={true}
          >
            SERVICES
          </Link>
        </h1>
        <h1 css={linkStyle}>
          <Link activeClassName="active_link" to="/imaging">
            TUJO IMAGING
          </Link>
        </h1>
        <h1 css={linkStyle}>
          <Link activeClassName="active_link" to="/courses">
            COURSES
          </Link>
        </h1>
        <h1 css={linkStyle}>
          <Link activeClassName="active_link" to="/contact">
            CONTACT
          </Link>
        </h1>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
